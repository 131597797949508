import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useDeleteResource, useAxios, useGetMedia, useAxiosB, useDeleteMediaEntry, useUploadToMusicar } from '../utils/hooks'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Pagination,Table,Collapse,Space, Button  } from 'antd'
import CreateMediaEntry from '../components/CreateMediaEntry'
import Myheader from '../components/Header'
import GroupContext from '../utils/GroupContext'

const defaultMediaCreate = [{value:"3DModel",label:"3D Model"},{value:"Image",label:"Image"},{value:"Scene",label:"Scene"}]

const SyncButton = (props) => {
  const [syncing,setSyncing] = React.useState(false);
  const uploadMusicar = useUploadToMusicar();
  const sync = (id) => {
    setSyncing(true);
    uploadMusicar(id).then((data)=>{}).finally(()=>{
      setSyncing(false);
    });
  }
  return (<Button disabled={syncing} onClick={()=>{sync(props.id)}}>Sync</Button>)
}

export default (props) => {
  //const { keycloak } = useKeycloak()
  const [data,setData] = React.useState([]);
  //const axiosInstance = useAxiosB();
  let history = useHistory();
  const urlparams = useParams();
  const [loading,setLoading] = React.useState(true)
  
  const [reload,setReload] = React.useState(Math.random())
  const { group, setGroup } = React.useContext(GroupContext);
  const removeElem = (elemid)=>{console.info("remove "+elemid);setData(data.filter(item => item.id != elemid))};
  const getMedia = useGetMedia();
  const deleteResourceH = useDeleteMediaEntry();
  
  const deleteResource = (resid,removeElem)=>{
    deleteResourceH(resid).then((result)=>{console.log(result);removeElem(resid);});
  }
  
  const createOptions = props.params && props?.params.type!=null?[{value:props.params.type,label:props.params.type}]:defaultMediaCreate;
  const showCreate = (props.params && props?.params.type!=null)?props?.params.type!='IMAGE360':true;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/mediamanagement/${record.id}/files`}>Dateien verwalten  </Link>
          <a onClick={()=>deleteResource(record.id,removeElem)}>Löschen</a>
          {record.type==="IMAGE360"&&<SyncButton id={record.id}></SyncButton>}
        </Space>
      ),
    }
  ];
React.useEffect(()=>{
  setLoading(true);
  getMedia(props.params ).then((result)=>{
    setData(result.data.content);
    setLoading(false);
  }).catch((e)=>{
    console.log("error",e)
  })
},[reload,group]);

  return (<div>
    <Myheader title={props.title?props.title:"Mediamanagement"}></Myheader>
    {!loading && 
    <div>
    <Table columns={columns} dataSource={data}></Table>
    {showCreate&&<CreateMediaEntry options={createOptions} onCreated={(result)=>{console.log(result);history.push(`/mediamanagement/${result.data.id}/files`)}}></CreateMediaEntry>}
    </div>
  }
  </div>)
}
