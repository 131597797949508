import { Select } from "antd";

import React, { useContext } from "react";
import GroupContext from "../utils/GroupContext";

import { useAxiosB, useKeyCloakGroups } from "../utils/hooks";

const GroupSelect = (props) => {
    let groups = useKeyCloakGroups();
    let axios = useAxiosB();

    const { group, setGroup } = useContext(GroupContext);
    if(!group){
        setGroup(groups[0].value);
    }
    //let groupsOptions = groups.map((item)=> { return { label: item, value: item } })
    console.log("groups",groups)
    return (<Select
        style={{ width: '10em' }}
        placeholder="Please select Group"
        value={group}
        onChange={(ev)=>{
            console.log("gc"+ev);
            //console.log("ax"+JSON.stringify(axios.current.defaults.headers));
            //axios.current.defaults.headers.common["FluxAuthCtx"] =ev
            setGroup(ev as string);
            localStorage.setItem('group', ev as string);
            //console.log("ax"+JSON.stringify(axios.current.defaults.headers));
        }}
        options={groups}
      ></Select>)
}

export default GroupSelect;