import React, { useEffect, useState } from "react";
import { useGetFileProgress } from "../utils/hooks";

const FileProgress = (props) => {
    const [progress,setProgress] = useState("Wird abgefragt");
    const getFileProgress = useGetFileProgress();
    useEffect(() => {
        const interval = setInterval(() => {
            getFileProgress(props.file.id).then((result)=>{
                if(result.data==="Ready"){
                    props.onReady();
                }
                setProgress(result.data+"%");
            })
        }, 5000);
        return () => clearInterval(interval);
      }, []);
    return (<div>{progress}</div>);
}


export default FileProgress;