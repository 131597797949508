import { Form, Input, Button, Checkbox, Select } from 'antd';
import * as React from 'react'
import { useHistory } from 'react-router';
import { formatWithOptions } from 'util';
import { useAxiosB, useCreateMediaEntry } from '../utils/hooks';
const { Option } = Select;
const CreateMediaEntry = (props) => {
  const mediaEntryCreate = useCreateMediaEntry();

  const onFinish = (values: any) => {
    console.log('Success:', values);
    mediaEntryCreate(values).then((result) => {
      props.onCreated(result);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [form] = Form.useForm();
  const onTypeChange = (value: string) => {
    console.log("set type ", value)
    form.setFieldsValue({ type: value });
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ type: props.options[0].value }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input a name!' }]}
      >
        <Input style={{ width: 200 }} />
      </Form.Item>
      {/* <Form.Item
        label="Type"
        name="type"
        hidden={true}
        rules={[{ required: true, message: 'Please input a name!' }]}
      >
        <Input style={{ width: 200 }} />
      </Form.Item> */}
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: 'Please input a type!' }]}
        hidden={props.options.length==1}
      >

        <Select defaultValue="3DModel" style={{ width: 120 }} onChange={onTypeChange}>
          {props.options && props.options.map((opt)=>{return (<Option key={opt.value} value={opt.value}>{opt.label}</Option>)})}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Hinzufügen
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateMediaEntry;