import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useAxios, useAxiosB, useGetDocument, useGetPrefab, usePatchNode } from '../utils/hooks'
import { Link } from 'react-router-dom'
import TreeTest from './tree'
import Formtest from './Formtest'
import { Row, Col, Button } from 'antd';
import { parse } from 'path'
import Myheader from '../components/Header'
import DocContext from '../utils/DocContext'
const schema = {
  "title": "Node properties",
  "type": "object",
  "required": [
    "id",
    "nodeType"
  ],
  // "additionalProperties": {
  //     "type": "string"
  //   },
  "properties": {
    "visible": {
      type: "boolean",
      default: true
    },
    "id": {
      "type": "number",
      "format": "Integer",
      "title": "Id"
    },
    "title": {
      "type": "string",
      "title": "title"
    },
    "nodeType": {
      "type": "string",
      "title": "Type",
      "examples": ["DATA", "PREFABINSTANCE", "BOX", "CUBE", "IMAGE360", "VIDEO360", "MODEL3D", "SPUNGPUNKT", "SUBDOCUMENT", "GROUP"]
    },
    "prefab": {
      "type": "string",
      "title": "Prefab",
      "format": "uuid"
    },
    "transform": {
      "title": "Transform",
      "description": "Location in 3D.",
      "type": "object",
      "required": [
        "scaleX",
        "scaleY"
      ],
      "properties": {
        "scaleX": {
          "type": "number",
          "title": "scaleX"
        },
        "scaleY": {
          "type": "number",
          "title": "scaleY"
        },
        "scaleZ": {
          "type": "number",
          "title": "scaleZ"
        },
        "translateX": {
          "type": "number",
          "title": "translateX",
          "default": 0.0
        },
        "translateY": {
          "type": "number",
          "title": "translateY",
          "default": 0.0
        },
        "translateZ": {
          "type": "number",
          "title": "translateZ",
          "default": 0.0
        },
        "rotateX": {
          "type": "number",
          "title": "rotateX",
          "default": 0.0,
          "min": -360,
          "max": 360
        },
        "rotateY": {
          "type": "number",
          "title": "rotateY",
          "default": 0.0
        },
        "rotateZ": {
          "type": "number",
          "title": "rotateZ",
          "default": 0.0
        }
      }
    }
  }
};
var res: any = {}
const getNodeFromDocById = (docroot, id) => {
  if (docroot.id === id)
    console.log("found id")
  if (docroot.id === id)
    res = docroot;
  else if (docroot.children != undefined)
    docroot.children.map((c) => getNodeFromDocById(c, id));
}


var docid = ''

export default (props) => {
  docid = props.match.params.id;
  const axiosInstance = useAxiosB();

  const [test, setTest] = React.useState({});
  const [nodeData, setNodeData] = React.useState({});
  const [prefabSchema, setPrefabSchema] = React.useState({});
  const [nodeSchema, setNodeSchema] = React.useState({});
  const [prefabSchemaId, setPrefabSchemaId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [reload, setReload] = React.useState(Math.random());
  const getDocument = useGetDocument();
  const getPrefab = useGetPrefab();
  const patchNode = usePatchNode();
  const onSubmit = (event: any) => {

    console.log(event)
    const data = event.formData;
    let sendData = { id: (nodeData as any).id, prefabProps: data };
    patchNode(docid, sendData.id, sendData)
      .then(res => { // then print response status
        console.log(res.statusText)
        console.log("reload data...");
        setReload(Math.random());
      })
  }
  const onSubmitNodeData = (event: any) => {

    console.log(event)
    const data = event.formData;
    patchNode(docid, data.id, data)
      .then(res => { // then print response status
        console.log(res.statusText)
        console.log("reload data...");
        setReload(Math.random());
      })
  }

  React.useEffect(() => {
    setLoading(true);
    getDocument(docid).then((result) => {
      setTest(result.data);
      setLoading(false);
    }).catch((e) => {
      console.log("error", e)
    })
  }, [reload]);
  React.useEffect(() => {

    let prefabId = (nodeData as any).prefab != null ? (nodeData as any).prefab : "";
    console.log("prefabId!==", prefabId !== "");
    prefabId !== "" && getPrefab(prefabId).then((result) => {

      console.log("setPrefabSchema", result.data)
      if (result.data.jsonSchema)
        setPrefabSchema(result.data.jsonSchema);
      setLoading(false);
    }).catch(e => {
      console.log("error on prefab catch")
    })
    let nodeSchemaC = JSON.parse(JSON.stringify(schema));
    if ((nodeData as any).fluxInterfaces) {
      let nifs = (nodeData as any).fluxInterfaces;
      
      nodeSchemaC["properties"]["props"] = { type: "object", title: "userprops", properties: {},definitions:{} };
      nifs.forEach((element) => {
        delete element.jsonSchema["additionalProperties"];
        nodeSchemaC["properties"]["props"]["properties"][element.dataPath] = { ...element.jsonSchema, title: "input for " + element.dataPath, type: "object", description: "desc" };
        if(element.jsonSchema.definitions){
          nodeSchemaC["definitions"] = {...nodeSchemaC["definitions"],...element.jsonSchema.definitions}
        }
        
      });

      console.log("nodeschema", JSON.stringify(nodeSchema));
    }
    setNodeSchema(nodeSchemaC);
  }, [nodeData]);
  return !loading && (
    <DocContext.Provider value={docid}>
    <React.Fragment>
      <Myheader title="Document Management"></Myheader>
      <Row>
        <Col span={24}>Fluxdocedit</Col><Button onClick={()=>setReload(Math.random())}>Reload</Button>
      </Row>
      <Row>
        <Col span={12}>
          <TreeTest
            axiosInstance={axiosInstance}
            docid={docid}
            data={test} editClicked={(id, data) => {
              setNodeData({ ...data });
            }}></TreeTest></Col>
        <Col span={12}>
          <Formtest data={nodeData} uiSchema={{ id: { "ui:disabled": true }, "ui:rootFieldId": "myform" }} schema={nodeSchema} onSubmit={onSubmitNodeData}></Formtest>
          <div>Prefabschema</div>
          <Formtest data={(nodeData as any).prefabProps} uiSchema={{ testMedia: { "ui:title": "Your password" }, "ui:rootFieldId": "myform2" }} schema={prefabSchema} onSubmit={onSubmit}></Formtest>

        </Col>
      </Row>
    </React.Fragment>
    </DocContext.Provider>

  )
}
