import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useAxios } from '../utils/hooks'
import { Link } from 'react-router-dom'
import Myheader from '../components/Header'

export default () => {
  const { keycloak } = useKeycloak()
  const [test,setTest] = React.useState("test");
  return (
    <div>
      <Myheader title="Home"></Myheader>
      <h1>Welcome</h1>
      {/* <Link to="/mediamanagement">Mediamanagement </Link>
      <p></p>
      <Link to="/doceditor/09cba144-8e7b-437b-a73f-5fed83055f21">Docedit new  </Link>
      <p></p>
      <Link to="/doceditor/2f70498b-70bc-4ffa-b708-bff429f5e556">Docedit</Link>
      <p></p>
      <Link to="/doceditor/f38c66db-ba84-4725-94d5-61261caa46a0">PrefabTest</Link>
      <p></p>
      <Link to="/doceditor/ba6e98e5-c949-492f-b5d0-e75169e1d5f2">LODModel3D</Link>
      <p></p>
      <Link to="/doceditor/0ad22855-84bd-4c11-985e-482c04228912">HFGroupSlotPrefab</Link>
      <p></p>
      <Link to="/doceditor/361cb8db-e63b-484a-9882-bc2cdcc30812">HFGroupTemplate Prefab</Link>
      <p></p>
      <Link to="/doceditorhf/e68a50bd-231f-45a1-b352-fc571348320d">HFGroupTemplate Special</Link>
      <p></p>
      <Link to="/doceditor/f82da942-c6ec-4d87-ad7d-a069b833ff2c">HFGroupTemplate</Link>
      <p></p>
      <Link to="/prefabeditor">PrefabEdit</Link>
      <div>
      <img src="http://localhost:8090/api/v1/mediaentry/af9bbdd1-5c9e-4443-ac42-ded3dbbc0048/fileobject/16"/>
      </div>
      <div>User is {!keycloak?.authenticated ? 'NOT ' : ''} authenticated</div>
      <img alt={test} src={`http://localhost:8090/api/media/mediaEntry/content/${test}`}></img>
      {!!keycloak?.authenticated && (
        <div>
        <button type="button" onClick={() => keycloak.logout()}>
          Logout
        </button>
        
        </div>
      )} */}
       {/* <input type="file" name="file" onChange={onChangeHandler}/>
      <button type="button" onClick={() => {callApi();setTest("d45")}}>
        Call API
      </button> */}
    </div>
  )
}
