import { Select } from 'antd';
import React, { Component, Fragment } from "react";
import { useAxios, useAxiosB } from '../utils/hooks';
import { TitleField } from '@rjsf/core';
import DocContext from '../utils/DocContext';
const { Option } = Select;

let timeout;
let currentValue;

function fetch(axiosInstance, value, callback, filter) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    axiosInstance.current.get(filter, { withCredentials: true }).then((result) => {
      //debugger;
      console.log("setting test")
      const data = [];
      if (result.data.content) {
        result.data.content.forEach(r => {
          data.push({
            value: r.id,
            text: r.name,
          });
        });
      } else {
        result.data.forEach(r => {
          data.push({
            value: r.id,
            text: r.displayName,
          });
        });
      }
      callback(data);
    }).catch((e) => {
      console.log("error", e)
    })
  }

  timeout = setTimeout(fake, 300);
}

class SearchResearch extends React.Component {
  state = {
    data: [],
    value: undefined,
  };

  handleSearch = value => {
    if (value) {
      fetch(this.props.axiosInstance, value, data => this.setState({ data }), this.props.schema.fluxPrefabProperty.reference.searchUrl.replace("{{docid}}", this.props.docid));
    } else {
      this.setState({ data: [] });
    }
  };

  handleChange = (value) => {
    this.setState({ value: value });
    this.props.onChange(value);
  };

  render() {
    //debugger;
    console.log(this.state.data)
    const options = this.state.data.map(d => <Option value={d.value} key={d.value}>{d.text}</Option>);
    return (

      <Select
        //showSearch
        value={this.props.formData}
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onClick={this.handleSearch}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
        title="Select something"
      >
        {options}
      </Select>
    );
  }
}


const SearchResearchComp = (props) => {
  const axiosInstance = useAxiosB();
  const docid = React.useContext(DocContext);
  const title = "testtitle";
  return (<Fragment>
    <label
      htmlFor={props.id}
      title={typeof title === 'string' ? title : ''}
    >
      {props.schema.title}
    </label>
    <SearchResearch axiosInstance={axiosInstance} docid={docid} {...props}></SearchResearch>

  </Fragment>)
}
export default SearchResearchComp;
//ReactDOM.render(<SearchInput placeholder="input search text" style={{ width: 200 }} />, mountNode);