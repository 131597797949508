import { Select } from 'antd';
import React, { Component } from "react";
import { useAxios, useAxiosB } from '../utils/hooks';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(axiosInstance, value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    axiosInstance.current.get('/api/v1/prefabs', { withCredentials: true }).then((result) => {
      //debugger;
      console.log("setting test")
      const data = [];
      if (result.data.content) {
        result.data.content.forEach(r => {
          data.push({
            value: r.id,
            text: r.name,
          });
        });
      } else {
        result.data.forEach(r => {
          data.push({
            value: r.id,
            text: r.name,
          });
        });
      }


      callback(data);
    }).catch((e) => {
      console.log("error", e)
    })
  }

  timeout = setTimeout(fake, 300);
}

class SearchInput extends React.Component {
  state = {
    data: [],
    value: undefined,
  };

  constructor(props) {
    super(props);
    console.log("setting state", props.formData);
    this.setState({ value: props.formData });
  }

  handleSearch = value => {
    if (value) {
      fetch(this.props.axiosInstance, value, data => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };

  handleChange = (value) => {
    this.setState({ value: value });
    this.props.onChange(value);
  };

  render() {
    //debugger;
    const options = this.state.data.map(d => <Option value={d.value} key={d.value}>{d.text}</Option>);
    return (
      <Select
        //showSearch
        value={this.props.formData}
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onClick={this.handleSearch}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
      >
        {options}
      </Select>
    );
  }
}


const SearchInput2 = (props) => {
  const axiosInstance = useAxiosB();
  return (<SearchInput axiosInstance={axiosInstance} {...props}></SearchInput>)
}
export default SearchInput2;
//ReactDOM.render(<SearchInput placeholder="input search text" style={{ width: 200 }} />, mountNode);