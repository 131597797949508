import * as React from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import HomePage from '../pages/Home'
import LoginPage from '../pages/Login'

import { PrivateRoute } from './utils'
import DocEditor from '../pages/DocEditor'
import PrefabEditor from '../pages/PrefabEditor'
import MediaManagement from '../pages/MediaManagement'
import FileManagement from '../pages/FileManagement'
import DocEditorHFGroup from '../pages/DocEditorHFGroup'
import DocumentMangement from '../pages/DocumentMangement'
import GroupContext from '../utils/GroupContext'
import RenderDialog from '../pages/RenderDialog'

export const AppRouter = () => {
  const { initialized } = useKeycloak()
  
  const initalGroup = localStorage.getItem('group');

  const [group,setGroup] = React.useState(initalGroup);
  const value = { group,setGroup };

  if (!initialized) {
    return <div>Loading...</div>
  }


  return (
    <GroupContext.Provider value={value}>
    <Router>
      <PrivateRoute  path="/" component={HomePage} exact/>
      <PrivateRoute  path="/home" component={HomePage} />
      <PrivateRoute path="/doceditor/:id" component={DocEditor} />
      <PrivateRoute path="/doceditorhf/:id" component={DocEditorHFGroup} />
      <PrivateRoute path="/render/:id" component={RenderDialog} />
      <PrivateRoute path="/prefabeditor" component={PrefabEditor} />
      <PrivateRoute path="/mediamanagement" exact component={MediaManagement} />
      <PrivateRoute path="/renderings" exact component={()=><MediaManagement title="Renderings" params={{type:'IMAGE360'}} allowcreate={false}></MediaManagement>} />
      <PrivateRoute path="/3dmodelmanagement" exact component={()=><MediaManagement title="3D Modelle" params={{type:'3DModel'}}></MediaManagement>} />
      <PrivateRoute path="/documentmanagement" exact component={DocumentMangement} />
      <PrivateRoute path="/mediamanagement/:id/files" component={FileManagement} />
      <Route path="/login" component={LoginPage} />
      {/* <Redirect exact from="/" to="/home" /> */}
    </Router>
    </GroupContext.Provider>
  )
}
