import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useAxios, useGetDocument, useGetFileUrl, useGetMediaEntryFilesByType, useGetPrefab, useGetRawFileUrl, usePatchNode, useRenderDocument } from '../utils/hooks'
import { Link } from 'react-router-dom'
import TreeTest from './tree'
import Formtest from './Formtest'
import { Row, Col, Space, Table, Button, Form, Input, Select, Radio, Checkbox } from 'antd';
import { parse } from 'path'
import Item from 'antd/lib/list/Item'
import Myheader from '../components/Header'
const schema = {
  "title": "Platzzuweisung",
  "type": "object",
  "required": [
    "id"
  ],
  // "additionalProperties": {
  //     "type": "string"
  //   },
  "properties": {
    "id": {
      "type": "number",
      "format": "Integer",
      "title": "Id"
    },
    "title": {
      "type": "string",
      "title": "Name"
    },
  }
};
var res: any = {}
const getNodeFromDocById = (docroot, id) => {
  if (docroot.id === id)
    console.log("found id")
  if (docroot.id === id)
    res = docroot;
  else if (docroot.children != undefined)
    docroot.children.map((c) => getNodeFromDocById(c, id));
}


var docid = ''

export default (props) => {
  console.log("start render of docedit")
  const { keycloak } = useKeycloak()
  //const axiosInstance = useAxios('http://localhost:8090') // see https://github.com/panz3r/jwt-checker-server for a quick implementation
  docid = props.match.params.id;


  const renderDoc = useRenderDocument();
  const [test, setTest] = React.useState({});
  const [nodeData, setNodeData] = React.useState({});
  const [prefabSchema, setPrefabSchema] = React.useState({});
  const [nodeSchema, setNodeSchema] = React.useState({});
  const [prefabSchemaId, setPrefabSchemaId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [editNodes, setEditNodes] = React.useState([]);
  const [imageSize, setImageSize] = React.useState(250);
  const [imageUrl,setImageUrl] = React.useState("");

  const [cameraNodes, setCameraNodes] = React.useState([]);
  const [cameraNodesCB, setCameraNodesCB] = React.useState([]);
  const [renderActive, setRenderActive] = React.useState(true);
  const qualityLevel = [{ label: "QA", value: 1000 }, { label: "Preview", value: 200 }, { label: "Production", value: 3000 }]
  const [reload, setReload] = React.useState(Math.random());
  const getDocument = useGetDocument();
  const getPrefab = useGetPrefab();
  const patchNode = usePatchNode();
  const getMediaEntryFilesByType = useGetMediaEntryFilesByType();
  const getFileUrl = useGetFileUrl();
  const showSceneImage = (mediaId) => {
    getMediaEntryFilesByType(mediaId,'.PNG').then((res)=>{
      console.log(res.data);
      if(res.data.length>0){
         let fileId = res.data[0].id;
         getFileUrl(mediaId,fileId).then((res)=>{
          setImageUrl(res.data);
         })
      }
    })
  }

  const onFinish = (values) => {
    console.log(values);
    if (values.rendercams.length === 0) {
      console.log("no camera selected")
    }
    renderDoc(docid, values.imageSize, values.rendercams,values.name).then((result) => {
      console.log("render rrsult" + result);
    });

    setRenderActive(false)
  };

  const columns = [
    {
      title: 'Platz Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Maschine',
      dataIndex: ['props', 'roomslot', 'machineModel'],
      key: 'id',
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => setNodeData(record)}>Anpassen </a>
        </Space>
      ),
    }
  ];

  const onSubmit = (event: any) => {

    console.log(event)
    const data = event.formData;
    let sendData = { id: (nodeData as any).id, prefabProps: data };
    patchNode(docid, sendData.id, sendData)
      .then(res => { // then print response status
        console.log(res.statusText)

      })
  }

  const findNodesByCrit = (root, crit, interestingNodes) => {
    if (crit(root)) {
      interestingNodes.push(root);
    }
    if (root.children) {
      root.children.forEach((child) => { findNodesByCrit(child, crit, interestingNodes) })
    }
  }

  const onSubmitNodeData = (event: any) => {
    console.log(event)
    const data = event.formData;
    patchNode(docid, data.id, data)
      .then(res => { // then print response status
        console.log(res.statusText)
        setReload(Math.random())
      })
  }

  //http://localhost:8090/api/info/docs/61d9018a-136a-494f-8672-e8f1381cb2ec
  React.useEffect(() => {
    setLoading(true);
    getDocument(docid).then((result) => {
      let crit = (item) => { return item.fluxInterfaces && item.fluxInterfaces.filter((fi) => fi.id === 7).length > 0; };
      let interestingNodes = [];
      findNodesByCrit(result.data.root, crit, interestingNodes);
      setEditNodes(interestingNodes);
      let crit2 = (item) => { return item.nodeType === 'CAMERA' };
      let interestingNodes2 = [];
      findNodesByCrit(result.data.root, crit2, interestingNodes2);
      setCameraNodesCB(interestingNodes2.map(item => { return { value: item.id, label: item.title } }));
      setCameraNodes(interestingNodes2);
      let crit3 = (item) => { return item.nodeType === 'DEBUG_FILE' };
      let interestingNodes3 = [];
      findNodesByCrit(result.data.root, crit3, interestingNodes3);
      if(interestingNodes3.length>0){
        let me = interestingNodes3[0].usedMediaEntryIds[0];
        showSceneImage(me);
      }
      
      setTest(result.data);
      setLoading(false);

    }).catch((e) => {
      console.log("error", e)
    })
  }, [reload]);
  React.useEffect(() => {
    //setLoading(true);
    //console.log("setPrefabSchema",prefabSchemaId);
    let prefabId = (nodeData as any).prefab != null ? (nodeData as any).prefab : "";
    console.log("prefabId!==", prefabId !== "");
    //debugger;
    getPrefab(prefabId).then((result) => {

      console.log("setPrefabSchema", result.data)
      if (result.data.jsonSchema)
        setPrefabSchema(result.data.jsonSchema);
      setLoading(false);
    }).catch(e => {
      console.log("error on prefab catch")
    })
    //debugger;
    let nodeSchemaC = JSON.parse(JSON.stringify(schema));
    if ((nodeData as any).fluxInterfaces) {
      let nifs = (nodeData as any).fluxInterfaces;

      nodeSchemaC["properties"]["props"] = { type: "object", title: "MachineSlot", properties: {} };
      nifs.forEach((element) => {
        //nodeSchema["properties"] = {...nodeSchema.properties,...element.jsonSchema.properties};

        delete element.jsonSchema["addtionalProperties"];
        nodeSchemaC["properties"]["props"]["properties"][element.dataPath] = { ...element.jsonSchema, type: "object" };
      });

    }
    setNodeSchema(nodeSchemaC);
  }, [nodeData,reload]);

  return !loading && (
    <React.Fragment>
      <Myheader title="Render Konfiguration anpassen"></Myheader>

      <Row>
      <Link to={`/render/${docid}`}>Die Konfiguration rendern</Link>
      </Row>
      <Row>
        <Col span={12}>
          <Table columns={columns} dataSource={editNodes}></Table>
          <img width="95%" src={imageUrl}></img>
        </Col>

        <Col span={12}>
        {Object.keys(nodeData).length != 0 && <Formtest data={nodeData} uiSchema={{ id: { "ui:disabled": true,"ui:widget": "hidden" }, title: { "ui:disabled": true }, "ui:rootFieldId": "myform" }} schema={nodeSchema} onSubmit={onSubmitNodeData}></Formtest>}

        </Col>
      </Row>
      
      {/* <Row>
        Cameras
        <Col span={12}>
          <Table columns={columns} dataSource={cameraNodes}></Table>
        </Col>
      </Row> */}
    </React.Fragment>

  )
}
