import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import React from "react";

export const CustomUpload  = (props)=> {
    const uploadprops = {
        beforeUpload: file => {
          // debugger;
          // const isPNG = file.type === 'image/png';
          // if (!isPNG) {
          //   message.error(`${file.name} is not a png file`);
          // }
          //return isPNG || Upload.LIST_IGNORE;
          return true;
        },
        onChange: info => {
          console.log(info.fileList);
        },
        customRequest:(data)=>{
          const { onSuccess, onError, file, onProgress } = data;
          let formData = new FormData();
          formData.append("file", data.file);
          props.uploadFile(props.docid, formData, event => {
            console.log((event.loaded / event.total) * 100);
            onProgress({ percent: (event.loaded / event.total) * 100 },file);
          }).then(({ data }) => {
            onSuccess(file);
            console.log(data)
          }).catch((err) => {
            const error = new Error('Upload Error');
            onError({event:error});
            console.log(err)
          }).finally(() => {
            props.setFileUploadDone(true);
            console.log("upload done")
          })
        }
      };
    return (<Upload {...uploadprops}>
        <Button icon={<UploadOutlined />}>{props.title}</Button>
      </Upload>);
}