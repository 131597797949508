import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useDeleteResource, useAxios, useGetMedia, useGetDocuments, useAxiosB, useKeyCloakRoles } from '../utils/hooks'
import { Link } from 'react-router-dom'
import { Pagination, Table, Collapse, Space } from 'antd'
import CreateMediaEntry from '../components/CreateMediaEntry'
import Myheader from '../components/Header'
import CreateDocument from '../components/CreateDocument'
import GroupContext from '../utils/GroupContext'

const deleteResource = (axiosInstance, resid, removeElem) => {
  axiosInstance.current.delete('/api/v1/cmsresources/' + resid, { withCredentials: true }).then((result) => { console.log(result); removeElem(resid); });
}

export default () => {
  const { keycloak } = useKeycloak()
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosB()
  const [loading, setLoading] = React.useState(true)
  const [reload, setReload] = React.useState(Math.random())
  const { group, setGroup } = React.useContext(GroupContext);
  const removeElem = (elemid) => setData(data.filter(item => item.id !== elemid));
  const getDocuments = useGetDocuments();
  const userroles = useKeyCloakRoles();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {userroles.includes("DEVELOPER")&&<Link to={`/doceditor/${record.id}`}>Complex Edit</Link>}
          <Link to={`/doceditorhf/${record.id}`}>Hallenkonfiguration</Link>
          <Link to={`/render/${record.id}`}>Render</Link>
          <a onClick={() => deleteResource(axiosInstance, record.id, removeElem)}>Löschen</a>
        </Space>
      ),
    }
  ];
  React.useEffect(() => {
    setLoading(true);
    console.log("loading testdata")
    getDocuments().then((result) => {
      setData(result.data.content);
      console.log(result.data);
      setLoading(false);
    }).catch((e) => {
      console.log("error", e)
    })
  }, [reload,group]);

  return (<div>
    <Myheader title="Hallenkonfigurationen"></Myheader>
    {!loading &&
      <div>
        <Table columns={columns} dataSource={data}></Table>
        <CreateDocument onCreated={() => setReload(Math.random())}></CreateDocument>
      </div>
    }
  </div>)
}
