import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useAxios, useAxiosB } from '../utils/hooks'
import { Link } from 'react-router-dom'

export default () => {
  const { keycloak } = useKeycloak()
  const [test,setTest] = React.useState("test");
  const axiosInstance = useAxiosB();
  const callApi = useCallback(() => {
    !!axiosInstance.current && axiosInstance.current.get('/api/media/',{withCredentials: true})
  }, [axiosInstance,test])

  const onChangeHandler= (event:any)=>{

      console.log(event.target.files[0])
      let selectedfile = event.target.files[0];
      const data = new FormData() 
    data.append('file', selectedfile)
    !!axiosInstance.current && axiosInstance.current.post("/api/media/upload", data, { 
      params: {bucket: 'test', provider: 'filesystem'},
      withCredentials: true
  })
  .then(res => { // then print response status
    console.log(res.statusText)
    setTest(res.data.id);
  })
  }

  console.log("rerender")
  return (
    <div>

      
      <div>User is {!keycloak?.authenticated ? 'NOT ' : ''} authenticated</div>
      <img alt={test} src={`http://localhost:8090/api/media/mediaEntry/content/${test}`}></img>
      {!!keycloak?.authenticated && (
        <div>
        <button type="button" onClick={() => keycloak.logout()}>
          Logout
        </button>
        
        </div>
      )}
       <input type="file" name="file" onChange={onChangeHandler}/>
      <button type="button" onClick={() => {callApi();setTest("d45")}}>
        Call API
      </button>
    </div>
  )
}
