import React, { Component } from 'react';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import { Link } from 'react-router-dom'

// In your own app, you would need to use import styles once in the app
// import 'react-sortable-tree/styles.css';
import 'react-sortable-tree/style.css';


const buildTreeData = (elem) => {
  let ch = elem.children != undefined ? elem.children : [];
  let { children, ...rest } = elem;
  console.log("nodekey##" + elem.id + "_" + elem.instanceId);
  let nodeTitle = "Node#" + elem.id;
  if (elem.instanceId)
    nodeTitle += "_virtualNodeOfInstance#" + elem.instanceId;
  if (elem.title)
    nodeTitle = elem.title;
  return { ...rest, title: nodeTitle, subtitle: "" + elem.id, expanded: true, children: ch.map((child) => buildTreeData(child)) };
}



export default class TreeTest extends Component {
  constructor(props) {
    super(props);
    let rootnode = props.data.root;
    let treedataRoot = { title: 'Root', children: [] };
    let testree = buildTreeData(props.data.root);
    this.axiosInstance = props.axiosInstance;
    this.state = {
      treeData: [testree],
      addAsFirstChild: false,
    };
    this.docid = props.docid;
  }

  removeNode(node, path, getNodeKey) {
    (!!this.axiosInstance.current) && this.axiosInstance.current.delete('/api/v1/documents/' + this.docid + '/nodes/' + node.id, { withCredentials: true }).then((result) => {
      this.setState(state => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
        }),
      }))

    });
  }

  onMoveNode(node, nextParentNode, path) {
    const getNodeKey = ({ node }) => node.id + "_" + node.instanceId;
    console.log("moved")
    let order = 0.0;
    let numChilds = nextParentNode.children.length;
    //debugger;
    for (let i = 0; i < numChilds; i++) {
      let child = nextParentNode.children[i]
      if (child.id === node.id) {
        if (i === 0) {
          if (numChilds > 1) {

            order = nextParentNode.children[i + 1].sortOrder - 100.0;
          }
        }
        if (i < (numChilds - 1)) {
          order = (order + nextParentNode.children[i + 1].sortOrder) / 2.0;
        } else if (numChilds === 1) {
          order = 0.0;
        }
        else {
          order = order + 100.0;
        }
        break;
      }

      order = nextParentNode.children[i].sortOrder;

    }
    node.sortOrder = order;
    // this.setState(state => ({
    //   treeData: changeNodeAtPath({
    //     treeData: state.treeData,
    //     path: path,
    //     newNode: {...node},
    //     getNodeKey,
    //   }).treeData,
    // }))

    console.log("new order" + order);
    let sendData = { id: node.id, parentId: nextParentNode.id, sortOrder: order, instanceId: nextParentNode.instanceId };
    !!this.axiosInstance.current && this.axiosInstance.current.patch("/api/v1/documents/" + this.docid + "/nodes/" + node.id, sendData, {
      withCredentials: true
    })
      .then(res => {
        console.log(res.statusText)
      })
  }

  createNewNode(node, path, getNodeKey) {
    let newNode = {
      nodeType: "DATA",
      parentId: node.id,
      instanceId: node.instanceId,
      props: {},
      prefabProps: {},
      transform: {
        quaternionW: 0,
        quaternionX: 0,
        quaternionY: 0,
        quaternionZ: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scaleX: 1,
        scaleY: 1,
        scaleZ: 1,
        translateX: 0,
        translateY: 0,
        translateZ: 0,
        zorder: 0
      }
    };

    !!this.axiosInstance.current && this.axiosInstance.current.post('/api/v1/documents/' + this.docid + '/nodes', newNode, { withCredentials: true }).then((result) => {
      //debugger;
      this.setState(state => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey,
          newNode: { ...result.data, title: "Node" + result.data.id, subtitle: result.data.sortOrder },
          addAsFirstChild: state.addAsFirstChild,
        }).treeData,
      }))
      this.props.onChange()
    }).catch(e => {
      console.log("error on prefab catch")
    })

  }


  render() {
    const getNodeKey = ({ node }) => node.id + "_" + node.instanceId;

    const recordCall = (name, args) => {
      // eslint-disable-next-line no-console
      console.log(`${name} called with arguments:`, args);
    };
    return (
      <div>
        <div style={{ height: 1000 }}>
          <SortableTree
            getNodeKey={({ node }) => node.id + "_" + node.instanceId}
            treeData={this.state.treeData}
            onChange={treeData => this.setState({ treeData })}
            onMoveNode={args => {
              recordCall('onMoveNode', args);
              const { prevPath, nextPath, node, path, nextParentNode } = args;
              this.onMoveNode(
                node, nextParentNode, path
              );
            }}
            canDrop={({ nextParent }) => {
              console.log(nextParent);
              return nextParent.editable;
            }}
            generateNodeProps={({ node, path }) => ({
              canDrag: node.editable,
              canDrop: node.editable,
              className: node.prefab ? "prefab-node" : (node.instanceId && node.editable ? "instance-node" : (node.instanceId && !node.editable) ? "virtual-node" : ""),
              buttons: [
                <button
                  onClick={() =>
                    this.createNewNode(node, path, getNodeKey)
                  }
                >
                  Add Child
                </button>,
                <button
                  onClick={() =>
                    this.removeNode(node, path, getNodeKey)

                  }
                >
                  Remove
                </button>,
                <button
                  onClick={() => {
                    let test = path;
                    console.log("edit clicked", test)
                    this.props.editClicked(path[path.length - 1], node);
                    //this.edit(getNodeKey)
                  }
                  }
                >
                  Edit
                </button>,
                node.prefab ? <Link to={"/doceditor/" + node.prefab}>Prefabedit</Link> : null
              ],
            })}
          />
        </div>
      </div>
    );
  }
}