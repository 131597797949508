import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useAxios, useGetDocument, useGetPrefab, usePatchNode, useRenderDocument } from '../utils/hooks'
import { Link, useHistory } from 'react-router-dom'
import TreeTest from './tree'
import Formtest from './Formtest'
import { Row, Col, Space, Table, Button, Form, Input, Select, Radio, Checkbox } from 'antd';
import { parse } from 'path'
import Item from 'antd/lib/list/Item'
import Myheader from '../components/Header'
const schema = {
  "title": "Node properties",
  "type": "object",
  "required": [
    "id"
  ],
  // "additionalProperties": {
  //     "type": "string"
  //   },
  "properties": {
    "id": {
      "type": "number",
      "format": "Integer",
      "title": "Id"
    },
    "title": {
      "type": "string",
      "title": "title"
    },
  }
};
var res: any = {}
const getNodeFromDocById = (docroot, id) => {
  if (docroot.id === id)
    console.log("found id")
  if (docroot.id === id)
    res = docroot;
  else if (docroot.children != undefined)
    docroot.children.map((c) => getNodeFromDocById(c, id));
}


var docid = ''

export default (props) => {
  const { keycloak } = useKeycloak()
  //const axiosInstance = useAxios('http://localhost:8090') // see https://github.com/panz3r/jwt-checker-server for a quick implementation
  docid = props.match.params.id;
  const getDocument = useGetDocument();

  const renderDoc = useRenderDocument();

  const [loading, setLoading] = React.useState(true);

  const history = useHistory();
  const [cameraNodes, setCameraNodes] = React.useState([]);
  const [cameraNodesCB, setCameraNodesCB] = React.useState([]);
  const [renderActive, setRenderActive] = React.useState(true);
  const qualityLevel = [ { label: "Vorschau", value: 200 },{ label: "QA", value: 1000 }, { label: "Production", value: 3000 }]
  const onFinish = (values) => {
    console.log(values);
    if (values.rendercams.length === 0) {
      console.log("no camera selected")
    }
    renderDoc(docid, values.imageSize, values.rendercams,values.name).then((result) => {
      console.log("render rrsult" + result);
      history.push(`/mediamanagement/${result.data}/files`);
    });

    setRenderActive(false)
  };
  const findNodesByCrit = (root, crit, interestingNodes) => {
    if (crit(root)) {
      interestingNodes.push(root);
    }
    if (root.children) {
      root.children.forEach((child) => { findNodesByCrit(child, crit, interestingNodes) })
    }
  }
  React.useEffect(() => {
    setLoading(true);
    getDocument(docid).then((result) => {
      let crit2 = (item) => { return item.nodeType === 'CAMERA' };
      let interestingNodes2 = [];
      findNodesByCrit(result.data.root, crit2, interestingNodes2);
      setCameraNodesCB(interestingNodes2.map(item => { return { value: item.id, label: item.title } }));
      setCameraNodes(interestingNodes2);
      
      setLoading(false);

    }).catch((e) => {
      console.log("error", e)
    })
  }, []);

  //http://localhost:8090/api/info/docs/61d9018a-136a-494f-8672-e8f1381cb2ec


  return !loading && (
    <React.Fragment>
      <Myheader title="Render"></Myheader>
      <Row>
        <Col span={12}>
          <h1>Für diese Hallenkonfiguration 360 Grad Bilder erstellen:</h1>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              
              //rules={[{ required: true, message: 'Please input a name!' }]}
            >
              <Input placeholder="Wird automatisch erstellt falls leer"/>
            </Form.Item>
            <Form.Item
              label="Render Qualität"
              name="imageSize"
              rules={[{ required: true, message: 'Bitte wählen sie eine Qualität aus!' }]}
            >
              <Select
                style={{ width: '10em' }}
                placeholder="Bitte wählen sie eine Qualität aus"
                //onChange={handleChange}
                options={qualityLevel}
              />
            </Form.Item>
            <Form.Item label="Kameras"
              name="rendercams"
              rules={[{ required: true, message: 'Es muss mindestens eine Kamera ausgewählt werden!' }]}
              initialValue={cameraNodesCB.map((item)=>{return item.value})}>
              <Checkbox.Group options={cameraNodesCB} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              {/* <Button disabled={!renderActive} onClick={()=>{renderDoc(docid,imageSize,cameraNodes.map((item)=>{return item.id}));setRenderActive(false)}}>Render</Button>  */}
              <Button disabled={!renderActive} type="primary" htmlType="submit">
                Render
              </Button>
            </Form.Item>
          </Form>

        </Col>
      </Row>
    </React.Fragment>

  )
}
