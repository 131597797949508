import React, { Component } from "react";
import { render } from "react-dom";
//import Form from '@rjsf/antd';
//import Form from "react-jsonschema-form";
import { withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import 'antd/dist/antd.css';
import { Col, InputNumber, Row } from "antd";
import SearchInput from "./SearchInput";
import SearchInput2 from "./SearchInput";
import SearchResearchComp from "./SearchInputResource"
const Form = withTheme(AntDTheme);


const CustomCheckbox = function(props) {
  return (
    <button id="custom" className={props.value ? "checked" : "unchecked"} onClick={() => props.onChange(!props.value)}>
        {String(props.value)}
    </button>
  );
};

const widgets = {
  CheckboxWidget: CustomCheckbox,

};

class GeoPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.formData;
  }

  onChange(name) {
    return (event) => {
      this.setState({
        [name]: parseFloat(event.target.value)
      }, () => this.props.onChange(this.state));
    };
  }

  onChange2(name,event) {

      this.setState({
        [name]: parseFloat(event.target.value)
      }, () => this.props.onChange(this.state));

  }

  render() {
    //this.setState({...this.props.formData})
    const {scaleX, scaleY,scaleZ,translateX,translateY,translateZ,rotateX,rotateY,rotateZ} = this.props.formData;
    return (
      <div>
      <Row gutter={[32, 24]}>
        <Col span={8}><input type="number" value={translateX} onChange={this.onChange("translateX")} /></Col>
        <Col span={8}><input type="number" value={translateY} onChange={this.onChange("translateY")} /></Col>
        <Col span={8}><input type="number" value={translateZ} onChange={this.onChange("translateZ")} /></Col>
      </Row>

      <Row gutter={[32, 24]}>
        <Col span={8}><input type="number" value={rotateX} onChange={this.onChange("rotateX")} /></Col>
        <Col span={8}><input type="number" value={rotateY} onChange={this.onChange("rotateY")} /></Col>
        <Col span={8}><input type="number" value={rotateZ} onChange={this.onChange("rotateZ")} /></Col>
      </Row>
      
      <Row gutter={[32, 24]}>

         <Col span={8}>
         <InputNumber
              value={scaleX}
          min="-10"
          max="10"

          onChange={(val)=>this.onChange2("scaleX",{target:{value:val}})}
        />
           </Col>
         <Col span={8}><input type="number" value={scaleY} onChange={this.onChange("scaleY")} /></Col>
         <Col span={8}><input type="number" value={scaleZ} onChange={this.onChange("scaleZ")} /></Col>
      </Row>
      </div>
      
      
    );
  }
}



// Define the custom field components to register; here our "geo"
// custom field component
const fields = {geo: GeoPosition,
                prefab:SearchInput2,
                resource:SearchResearchComp};

export default (props) => {

const formData = {

  };

const uiSchema = {"transform":{"ui:field": "geo"},"prefab":{"ui:field": "prefab"}};
const log = (type) => console.log.bind(console, type);
const schemaToUse = props.schema;
let customUi = {props : {roomslot:{filetype:{ "ui:widget": "hidden"}}}}
if(props.schema&&schemaToUse.properties){
  //debugger;
  addCmsResourceUiHint(schemaToUse, customUi);
  console.log(customUi);
}
const uiSchemaMerged = {...customUi,...props.uiSchema,...uiSchema}
console.log(JSON.stringify(uiSchemaMerged));
console.log(JSON.stringify(schemaToUse));
return(
  <Form schema={schemaToUse}
        widgets={widgets}
        fields={fields}
        formData={props.data}
        uiSchema={uiSchemaMerged}
        onChange={log("changed")}
        onSubmit={props.onSubmit}
        onError={log("errors")} />
)
}

function addCmsResourceUiHint(schemaToUseF, customUiF) {

  let items = schemaToUseF.properties?Object.entries(schemaToUseF.properties):Object.entries({...schemaToUseF.items});
  
  for (const [key, value] of items) {
    //debugger;
    if (value.fluxPrefabProperty && value.fluxPrefabProperty.uiType && value.fluxPrefabProperty.uiType.startsWith("cms")) {

      customUiF[key] = { "ui:field": "resource" };
    }

    if(value.type=="object"){
        if(!customUiF[key]){
          customUiF[key]={}
         }
         if(!schemaToUseF["properties"]){
           console.log("fail")
        }
        let otherSchemaToUse = schemaToUseF["properties"][key];
        let otherCustomUi = customUiF[key];
        addCmsResourceUiHint(otherSchemaToUse,otherCustomUi);

      }
      if(value.type=="array"){
        //debugger;
        if(!customUiF[key]){
          customUiF[key]={"items":{}}
         }
      //   debugger;
        let otherSchemaToUse = schemaToUseF["properties"][key]["items"].type==='object'?schemaToUseF["properties"][key]["items"]:schemaToUseF["properties"][key];
        let otherCustomUi = customUiF[key]["items"];
        if(otherSchemaToUse.properties){
          addCmsResourceUiHint(otherSchemaToUse,otherCustomUi);
        }
          else{
            if (otherSchemaToUse.items.fluxPrefabProperty && otherSchemaToUse.items.fluxPrefabProperty.uiType && otherSchemaToUse.items.fluxPrefabProperty.uiType.startsWith("cms")) {
              customUiF[key]["items"] = { "ui:field": "resource" };
            }
          }

      }
  };
}
