import { Form, Input, Button, Checkbox, Select } from 'antd';
import * as React from 'react'
import { useEffect } from 'react';
import { useAxiosB, useCreateDocument, useFindMedia, useGetDocument, useImportGltf } from '../utils/hooks';
const CreateDocument = (props) => {
  const [scenes, setScenes] = React.useState([]);
  const documentCreate = useCreateDocument();
  const getDocument = useGetDocument();
  const importGltf = useImportGltf();
  const findMedia = useFindMedia();
  const handleChange = (item) => { console.log(item) };
  const onFinish = (values: any) => {
    console.log('Success:', values);
    documentCreate(values).then((result) => {
      let docid = result.data.id;
      getDocument(result.data.id).then((docres) => {
        let rootnode = docres.data.root.id;
        importGltf(values.scene, docid, rootnode).then((importres) => {
          props.onCreated();
        })
      });
    });

  };
  useEffect(() => {
    findMedia('Scene')
      .then((result) => {
        console.log(result.data);
        let tmp = result.data.content.map((item) => { return { label: item.name, value: item.id } })
        setScenes(tmp);
      }).catch((e) => {
        console.log("error", e)
      })
  }, []);
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Bitte einen Namen angeben!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Scene"
        name="scene"
        rules={[{ required: true, message: 'Bitte Halle auswählen!' }]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Bitte Halle auswählen"
          onChange={handleChange}
          options={scenes}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Neue Konfiguration erstellen
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateDocument;