import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import jwt_decode from "jwt-decode";
import { useKeycloak } from '@react-keycloak/web';
import GroupContext from './GroupContext';


//const baseURL = process.env.REACT_APP_BASEURL;

const baseURL = process.env.NODE_ENV==='development'?process.env.REACT_APP_BASEURL:'https://'+window.location.hostname;

export const useAxios = (baseURL: string) => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';
  var decoded = jwt_decode(kcToken);
  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
        //FluxAuthCtx:"48a03aeb-446c-4f71-b1a0-1e7bf3f28823"
        FluxAuthCtx:"f0a6bbd4-aa36-4fe2-988b-98be51b19a47"
      },
    });

    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL, initialized, kcToken]);

  return axiosInstance;
};

export const useKeyCloakGroups = ()=>{
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';
  var decoded:any = jwt_decode(kcToken);
  console.log("decoded:"+decoded)
  let groupOpt = decoded.flux_groups.map((k, i) =>{return {value:k, label:decoded.flux_groupsn[i]}})
  return groupOpt;
}

export const useKeyCloakRoles = ()=>{
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';
  var decoded:any = jwt_decode(kcToken);
  return decoded.resource_access.fluxcms.roles;
}

export const useAxiosB = () => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';
  var decoded:any = jwt_decode(kcToken);
  console.log("decoded:"+JSON.stringify(decoded))
  const {group,setGroup} = useContext(GroupContext);
  const [a,setA] =useState("");
  useEffect(() => {
    console.log("create axios instance",group);
    axiosInstance.current = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
        FluxAuthCtx:group
      },
    });

   
  }, [baseURL, initialized, kcToken,group]);

  return axiosInstance;
};



export const useCreateMediaEntry = () => {
  const axiosInstance = useAxiosB();
  const metadata =    {
		name: "TestGltf",
		description: "TestGltf",
		type: "MEDIA"
	};
  return (mediaEntry:any) => !!axiosInstance.current && axiosInstance.current.post('/api/v1/cmsresources/media',{data:{autoApplyJobs:mediaEntry.type=="3DModel"?["CAD"]:[],...mediaEntry},metaData:metadata},{withCredentials: true});
}

export const useCreateDocument = () => {
  const axiosInstance = useAxiosB();
  const metadata =    {
		type: "DOCUMENT"
	};
  return (docdata:any) => !!axiosInstance.current && axiosInstance.current.post('/api/v1/cmsresources/document',{metaData:{name:docdata.name,...metadata},data:{...docdata}},{withCredentials: true});
}

export const useGetDocument = () => {
  const axiosInstance = useAxiosB();
  return (docid:any) =>!!axiosInstance.current && axiosInstance.current.get('/api/v1/documents/'+docid,{withCredentials: true})
}

export const usePatchNode = () => {
  const axiosInstance = useAxiosB();
  return (docid:any,nodeid:any,data:any) =>!!axiosInstance.current && axiosInstance.current.patch(`/api/v1/documents/${docid}/nodes/${nodeid}`, data, { 
    withCredentials: true
  });
}



export const useGetPrefab = () => {
  const axiosInstance = useAxiosB();
  return (prefabId:any) =>!!axiosInstance.current && axiosInstance.current.get('/api/v1/prefabs/'+prefabId,{withCredentials: true})
}

export const useImportGltf = () => {
  const axiosInstance = useAxiosB();
  return (mediaentryid:any,docid:any,nodeid:any) =>!!axiosInstance.current && axiosInstance.current.post(`/api/v1/mediaentry/${mediaentryid}/importgltf?docid=${docid}&nodeid=${nodeid}`,{withCredentials: true})
}

export const useGetMedia = () => {
  const axiosInstance = useAxiosB();
  return (params={}) => !!axiosInstance.current && axiosInstance.current.get(`/api/v1/mediaentry/find?size=100`,{params:params,withCredentials: true});
}

export const useFindMedia = () => {
  const axiosInstance = useAxiosB();
  return (type) => !!axiosInstance.current && axiosInstance.current.get(`/api/v1/mediaentry/find?type=${type}&size=100`,{withCredentials: true});
}



export const useGetDocuments = () => {
  const axiosInstance = useAxiosB();
  return () => !!axiosInstance.current && axiosInstance.current.get('/api/v1/cmsresources?type=DOCUMENT',{withCredentials: true});
}

export const useRenderDocument = () => {
  const axiosInstance = useAxiosB();
  return (docid:any,imageSize,cameraNodes,name) =>!!axiosInstance.current && axiosInstance.current.post(`/api/info/${docid}/render`,{},{withCredentials: true,params:{imageSize:imageSize,cams2Render:cameraNodes.join(),documentName:name?name:null}})
}

export const useUploadToMusicar = () => {
  const axiosInstance = useAxiosB();
  return (docid:any) =>!!axiosInstance.current && axiosInstance.current.post(`/api/info/${docid}/uploadmusicar`,{},{withCredentials: true})
}


export const useUploadFile = () => {
  const axiosInstance = useAxiosB();
  return (docid,formData,progrssCallBack) => axiosInstance.current.post(`/api/v1/mediaentry/${docid}/upload?fileType=test`,formData,{onUploadProgress: progrssCallBack,withCredentials: true,headers: { "Content-Type": "multipart/form-data"}})
}

export const useUpdateFile = () => {
  const axiosInstance = useAxiosB();
  return (docid,fileid,formData) => axiosInstance.current.put(`/api/v1/mediaentry/${docid}/${fileid}/update`,formData,{withCredentials: true,headers: { "Content-Type": "multipart/form-data"}})
}

export const useGetMediaEntry = () => {
  const axiosInstance = useAxiosB();
  return (docid) => !!axiosInstance.current && axiosInstance.current.get(`/api/v1/mediaentry/${docid}`,{withCredentials: true})
}

export const useGetMediaEntryFilesByType = () => {
  const axiosInstance = useAxiosB();
  return (docid,fileType) => !!axiosInstance.current && axiosInstance.current.get(`/api/v1/mediaentry/${docid}/${fileType}`,{withCredentials: true})
}

export const useDeleteResource = () => {
  const axiosInstance = useAxiosB();
  return (resid) => !!axiosInstance.current && axiosInstance.current.delete('/api/v1/cmsresources/'+resid,{withCredentials: true})
}

export const useDeleteMediaEntry = () => {
  const axiosInstance = useAxiosB();
  return (resid) => !!axiosInstance.current && axiosInstance.current.delete(`/api/v1/mediaentry/${resid}`,{withCredentials: true})
}

export const useDeleteFile = () => {
  const axiosInstance = useAxiosB();
  return (docid,fileid) => axiosInstance.current.delete(`/api/v1/mediaentry/${docid}/fileobject/${fileid}`,{withCredentials: true})
}

export const useGetFileUrl = () => {
  const axiosInstance = useAxiosB();
  return (docid,fileid) => axiosInstance.current.get(`/api/v1/mediaentry/${docid}/fileobject/${fileid}`,{withCredentials: true,maxRedirects: 0})
}

export const useGetRawFileUrl = () => {
  const axiosInstance = useAxiosB();
  return (docid,fileid) => {return `${axiosInstance.current.defaults.baseURL}/api/v1/mediaentry/${docid}/fileobject/${fileid}`}
}

export const useGetFileProgress = () => {
  const axiosInstance = useAxiosB();
  return (fileid) => axiosInstance.current.get(`/api/v1/processing/file/${fileid}`,{withCredentials: true,maxRedirects: 0})
}





