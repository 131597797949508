import { PageHeader } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import GroupContext from '../utils/GroupContext';
import { useKeyCloakRoles } from '../utils/hooks';
import GroupSelect from './GroupSelect';



const Myheader = (props) => {
  const navigation = useHistory();
  const userroles = useKeyCloakRoles();
  console.log("userroles"+userroles)
  return (
 
    <PageHeader
    className="site-page-header"
    onBack={() => navigation.goBack()}
    extra={[
      <GroupSelect onChange={(ev)=>console.log("change group"+ev)}></GroupSelect>,
      // <Link key="2" to="/home">Home </Link>,
      userroles.includes("DEVELOPER")&&<Link key="1" to="/mediamanagement">Mediamanagement </Link>,
      <Link key="5" to="/3dmodelmanagement">3D Modelle </Link>,
      <Link key="6" to="/renderings">Erstellte Renderings</Link>,
      <Link key="4" to="/documentmanagement">Hallenkonfigurationen</Link>,
      // <Link key="3" to="/doceditor/361cb8db-e63b-484a-9882-bc2cdcc30812">HFGroupTemplate Prefab</Link>
    ]}
    {...props}
  />)
  
}

export default Myheader