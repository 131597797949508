import * as React from 'react'
import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

import { useDeleteResource, useAxios, useUploadFile, useUpdateFile, useGetMediaEntry, useDeleteFile, useGetFileUrl, useGetFileProgress, useGetRawFileUrl } from '../utils/hooks'
import { Link } from 'react-router-dom'
import { Pagination, Table, Collapse, Space, Row, Col } from 'antd'
import { Upload, message, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Pannellum } from "pannellum-react";
import Myheader from '../components/Header'
import FileProgress from '../components/FileProgress'
import { CustomUpload } from '../components/CustomUpload'
import { CustomUpdate } from '../components/CustomUpdate'

const { Panel } = Collapse;

// const deleteResource = (axiosInstance, resid, removeElem) => {
//   axiosInstance.current.delete('/api/v1/cmsresources/' + resid, { withCredentials: true }).then((result) => { console.log(result); removeElem(resid); });
// }

const addFile = () => {

}

export default (props) => {
  const [data, setData] = React.useState([]);
  const [docdata, setDocdata] = React.useState({} as any);
  const [loading, setLoading] = React.useState(true);
  const [reload, setReload] = React.useState(Math.random())
  const [fileList, setFileList] = React.useState([]);
  const [fileUploadDone, setFileUploadDone] = React.useState(false);
  const docid = props.match.params.id;
  const [currentUrl, setCurrentUrl] = React.useState("");
  const [uploading,setUploading] = React.useState(false);
  const uploadFile = useUploadFile();
  const updateFile = useUpdateFile();
  const getMediaEntry = useGetMediaEntry();
  const deleteFile = useDeleteFile();
  const getFileUrl = useGetFileUrl();
  const getRawFileUrl = useGetRawFileUrl();
  const getFileProgress = useGetFileProgress();
  const removeElem = (fileid) => { deleteFile(docid, fileid).then((result) => { console.log(result); setFileUploadDone(true) }); };
  const getUrl = (fileid) => {
    return getFileUrl(docid, fileid).then((result) => {
      setCurrentUrl(result.data);
      return result.data;

    }).catch((e) => {
      console.log("error", e)
    })
  }
  const downloadFile = (fileid) => {
    getFileUrl(docid, fileid).then((result) => {

      //console.log(result.data);
      // let filePath = result.data;
      // var link=document.createElement('a');
      // link.href = filePath;
      // link.download = '397.png'//filePath.substr(filePath.lastIndexOf('/') + 1)'';
      // console.log("download ",link.download)
      // link.click();
      //    setCurrentUrl(result.data);
      window.location.assign(result.data);
    }).catch((e) => {
      console.log("error", e)
    })
  }
  // const handleUploadUpdate = (info, fileid) => {
  //   if (info.file.status !== 'uploading') {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (info.file.status === 'done') {
  //     message.success(`${info.file.name} file uploaded successfully`);
  //     setUploading(false);
  //   } else if (info.file.status === 'error') {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  //   let formData = new FormData();
  //   formData.append("file", info.fileList[0].originFileObj);
  //   updateFile(docid, fileid, formData).then(({ data }) => {
  //   }).catch((err) => {
  //     console.log(err)
  //   }).finally(() => {
  //     setFileUploadDone(true);
  //     console.log("update upload done")
  //   })

  // };
  // const handleUpload = (info) => {
  //   if (info.file.status !== 'uploading') {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (info.file.status === 'done') {
  //     message.success(`${info.file.name} file uploaded successfully`);
  //   } else if (info.file.status === 'error') {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  //   let formData = new FormData();
  //   formData.append("file", info.fileList[0].originFileObj);
  //   uploadFile(docid, formData,{}).then(({ data }) => {

  //     console.log(data)
  //   }).catch((err) => {
  //     console.log(err)
  //   }).finally(() => {
  //     setFileUploadDone(true);
  //     console.log("upload done")
  //   })
  // };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Dateiname',
      dataIndex: 'originalFileName',
      key: 'originalFileName',
    },
    {
      title: 'Fortschritt',
      key: 'progress',
      render: (text, record) => (
        record.usable?(<div>Fertig</div>):<FileProgress file={record} onReady={()=>setReload(Math.random())}></FileProgress>
      )
    },
    {
      title: 'Aktion',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {record.usable && <a onClick={() => downloadFile(record.id)}>Herunterladen </a>}
          {record.usable && record.preset==='IMAGE360' && <a onClick={() => getUrl(record.id)}>Ansehen </a>}
          {record.editable && <CustomUpdate title="Update" fileid={record.id} docid={docid} uploadFile={updateFile} setFileUploadDone={setFileUploadDone}></CustomUpdate>}
          <a onClick={() => { console.log("delete " + record.id); removeElem(record.id) }}>Löschen</a>
        </Space>
      ),
    }
  ];


  React.useEffect(() => {
    setLoading(true);
    console.log("loading testdata")
    if (!fileUploadDone) {
      getMediaEntry(docid).then((result) => {
        setData(result.data.root.children ? addKey(result.data.root.children) : []);
        setDocdata(result.data);
        console.log(result.data);
        setLoading(false);
      }).catch((e) => {
        console.log("error", e)
      })
    }
    setFileUploadDone(false);
  }, [fileUploadDone,reload]);


  // const handleSubmit = event => {
  //   event.preventDefault();
  //   let formData = new FormData();
  //   formData.append("file", fileList[0].originFileObj);
  //   uploadFile(docid, formData,{}).then(({ data }) => {
  //     //console.log(data)
  //   }).catch((err) => {
  //     console.log(err)
  //   }).finally(() => {
  //     setFileUploadDone(true);
  //     console.log("upload done")
  //   })
  // };
  // const uploadprops = {
  //   beforeUpload: file => {
  //     // debugger;
  //     // const isPNG = file.type === 'image/png';
  //     // if (!isPNG) {
  //     //   message.error(`${file.name} is not a png file`);
  //     // }
  //     //return isPNG || Upload.LIST_IGNORE;
  //     return true;
  //   },
  //   onChange: info => {
  //     console.log(info.fileList);
  //   },
  //   customRequest:(data)=>{
  //     const { onSuccess, onError, file, onProgress } = data;
  //     let formData = new FormData();
  //     formData.append("file", data.file);
  //     uploadFile(docid, formData, event => {
  //       console.log((event.loaded / event.total) * 100);
  //       onProgress({ percent: (event.loaded / event.total) * 100 },file);
  //     }).then(({ data }) => {
  //       onSuccess(file);
  //       console.log(data)
  //     }).catch((err) => {
  //       const error = new Error('Upload Error');
  //       onError({event:error});
  //       console.log(err)
  //     }).finally(() => {
  //       setFileUploadDone(true);
  //       console.log("upload done")
  //     })
  //   }
  // };
  return (<div><Myheader title={`Dateiverwaltung von ${docdata.name}`}></Myheader>
    {!loading &&
      <div>
        Dateiverwaltung von {docdata.name}
        <Row>
          <Col span={12}>
            <Table columns={columns} dataSource={data}></Table>
          </Col>
          {currentUrl!=""&&<Col span={12}>
            {/* <Image width="100%" src={currentUrl}></Image> */}
            <Pannellum
              width="800px"
              height="400px"
              mouseZoom={false}
              image={currentUrl}
              pitch={10}
              yaw={180}
              hfov={80}
              autoLoad
              showZoomCtrl={false}
            >
            </Pannellum>
          </Col>}
        </Row>
        <CustomUpload title="Datei hinzufügen" docid={docid} uploadFile={uploadFile} setFileUploadDone={setFileUploadDone}></CustomUpload>
        
        {/* <Button onClick={handleSubmit} // this button click will trigger the manual upload
        >Upload File</Button> */}
      </div>
    }
  </div>)
}

const addKey = (elems) => {
  return elems.map((elem) => {
    if (elem.children) {
      elem.children = addKey(elem.children);
    }
    return { ...elem, key: elem.id };
  })

}